$nav-z-index: 10;
$max-width: 500px;

$main-text-color: #555555;
$blue-light: #16c4ff;
$blue: #1EB2EA;
$blue-unread: #1daee9;
$blue-hover: #1eaae5;
$blue-darker: #00b1ed;
$black: #000;
$white: #FFF;
$grey-c: #CCC;
$grey-e: #ebebeb;
$grey-6: #606060;
$grey-9: #9C9C9C;
$text-time-ago-messages: #aeafaf;
$conversations-title: #84939c;
$unread-counter-red: #e24b69;
$conversations-name: #4a4a4a;
$conversations-content: #5b6c76;
$recipient-message-background: #f1f7fb;
$recipient-message-text: #666666;
$sender-message-background: #1daee9;
$sender-message-text: #FFF;
$nav-blue: #109ad3;
$nav-search-background: #e2f8ff;
$preferences-age-text: #222222;
$color-success: #4BB543;