.video-submit-form {
  padding: 20px 10px;
  max-width: $max-width;
  background: #f0f6fa;
  min-height: 100vh;

  .field-group {
    font-size: 14px !important;

    label, span, div.filename {
      padding-left: 5px;
    }

    span {
      color: $color-success;
    }

    input {
      width: 100%;
      border-radius: 100px;
      border: 1px solid $grey-e;
      padding: 10px;
      color: $black;
      font-size: 14px !important;
    }

    input[type=file] {
      width: 100%;
      border-radius: 100px;
      border: 1px solid $grey-e;
      padding: 10px;
      background: #fff;
      color: $black;
      font-size: 14px !important;
      text-shadow: none;
    }

    input[type=submit] {
      color: $black;
      margin-top: 10px;
      font-size: 14px !important;
      margin-bottom: 30px !important;
    }

    input[type=submit]:disabled {
      cursor: default !important;
      box-shadow: none !important;
      opacity: 0.5;
      margin-bottom: 30px !important;
    }

    input[type=submit]:hover {
      cursor: pointer;
      box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
    }

    input:focus {
      outline: none;
    }
  }

  .agree-to-terms {
    padding-left: 5px;

    h3 {
      padding-left: 5px;
      font-size: 14px;
      display: inline;
    }
  }
}