dl,dt,dd,
ul,ol,li,
h1,h2,h3,h4,h5,h6,
pre,code,
form,fieldset,legend,input,
textarea,p,blockquote,
th,td,
hr,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: BlinkMacSystemFont, -apple-system, 'Helvetica Neue', Helvetica, sans-serif;
  color: $main-text-color;
}

html, body {
  height: 100%;
}

a {
  color: $blue;

  &:hover {
    color: $blue-hover;
  }
}

#App-padd {
  height: 56px;
}

#App-content {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
}

.fwb {
  font-weight: bold !important;
}

.tac {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.ib {
  display: inline-block;
}

.hidden {
  display: none !important;
}

.notification-container {
  position: fixed;
  top: 50px;
  z-index: 20;
  width: 100%;
  max-width: $max-width;
}

.red {
  color: #e24b69;
  font-weight: 500;
}

.notification {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  color: #fff;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 20px;
  margin-bottom: 15px;
  animation: grow 0.5s ease-in forwards;
}

@keyframes grow {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.notification.hide {
  animation: shrink 0.3s ease-out forwards;
}

@keyframes shrink {
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.notification strong {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.notification-info {
  background-color: #1daee9;
  & a {
    text-decoration: none !important;
  }
}