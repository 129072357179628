$bg-blue-price: #73BDEB;
@import "font/style";

.navigation {
  max-width: 500px;
}
button.user--fixed-bottom-area--send-button {
  border: none;
  background: none;
}
.conversations--list--item--photo {
  overflow: auto;
  white-space: nowrap;
}

.popup-content {
  background: none !important;
  border: none !important;
}
.popup-overlay {
  background: none !important;
}

.form-error-display {
  display: block;
  color: indianred;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.form-error-display-user-profile {
  display: inline-block;
  width: 100%;
  color: indianred;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background: #fff;
}

.button-save {
  width: 100%;
  font-size: 16px !important;
  margin-bottom: 40px;
}
.button-blue {
  margin-top: 35px;
  display: inline-block;
  padding: 0 20px;
  background-image: linear-gradient(102deg, #16c4ff, #1eaae5);
  font-weight: 600;
  color: $white;
  height: 58px;
  line-height: 58px;
  border-radius: 100px;

  &:hover {
    color: #FFF;
  }
}

.unread-counter {
  font-family: BlinkMacSystemFont, -apple-system, 'Helvetica Neue', Helvetica, sans-serif;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;

  font-size: 14px;
  background: #e24b69;
  color: #FFF;
  border-radius: 100px;
}

// Conversation is not allowed popup
.conversations-is-locked-popup-user-img {
  width: 122px;
  height: auto;
  border-radius: 100px;
  padding: 3px;
  border: 2px solid #c4d2db;
}
// Search preferences popup
.search-preferences-icons {
  height: 72px;
  width: 72px;
  margin-bottom: 10px;
}

// Spinner
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(115, 189, 235, 0.2);
  border-right: 1.1em solid rgba(115, 189, 235, 0.2);
  border-bottom: 1.1em solid rgba(115, 189, 235, 0.2);
  border-left: 1.1em solid #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Spinner
.loader-small,
.loader-small:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader-small {
  margin: 5px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(115, 189, 235, 0.2);
  border-right: 1.1em solid rgba(115, 189, 235, 0.2);
  border-bottom: 1.1em solid rgba(115, 189, 235, 0.2);
  border-left: 1.1em solid #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* 404 Page */
.page-not-found {
  margin-top: 20vh;
  max-width: $max-width;
  text-align: center;
}

/* Popups for mobile users */
.appStore-image {
  max-width: $max-width;
  height: 102px;
  margin: auto;
  background-repeat: no-repeat;
  background-image: url('/app/images/mobile_appstore.png?version=1');
  background-size: 100%;
  margin-top: 25px;
}

.appStore-image:hover {
  background-image: url('/app/images/mobile_appstore_hover.png?version=1');
}

.android-image {
  max-width: $max-width;
  height: 102px;
  margin: auto;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-image: url('/app/images/google-play.png?version=1');
  background-size: 100%;
}

.discount-background {
  width: $max-width;
  margin-left: -20px;
  margin-top: -10px;
  padding: 10px 40px;
  text-align: center;
  background-image: linear-gradient(173deg, #f68e07 -44%, #cd07cb 111%);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.19px;
  color: #ffffff;

  h1 {
    font-size: 18px;
    font-weight: 700;
  }
}

.discount-background-login {
  width: 100%;
  max-width: $max-width;
  padding: 10px 40px;
  text-align: center;
  background-image: linear-gradient(173deg, #f68e07 -44%, #cd07cb 111%);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.19px;
  color: #ffffff;

  h1 {
    font-size: 18px;
    font-weight: 700;
  }
}

.discount-background-page {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: $max-width;
  padding: 10px 40px;
  text-align: center;
  background-image: linear-gradient(173deg, #f68e07 -44%, #cd07cb 111%);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.19px;
  color: #ffffff;

  h1 {
    font-size: 18px;
    font-weight: 700;
  }
}
.is-discount-header-clear {
  margin-top: 65px;
}

.button-grey {
  margin-top: 35px;
  display: inline-block;
  padding: 0 20px;
  background-image: linear-gradient(102deg, #cccccc, #d2d2d2);
  font-weight: 600;
  color: $white;
  height: 58px;
  line-height: 58px;
  border-radius: 100px;

  &:hover {
    color: #FFF;
  }
}