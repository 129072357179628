@import "details/apple-switch";
$bg-settings: #f0f6fa;

.settings-private {
  background: $bg-settings;
  min-height: 90vh;

  a {
    color: $main-text-color;
  }

  .spacer-60 {
    width: 100%;
    height: 60px;
  }

  &--container {
    background: $white;
    height: 50px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $grey-9;

    &.last {
      border-bottom: 1px solid $grey-9;
    }

    &--item {
      display: inline-flex;
    }
  }
}
