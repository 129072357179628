@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ywvu0a');
  src:  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ywvu0a#iefix') format('embedded-opentype'),
  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ywvu0a') format('truetype'),
  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ywvu0a') format('woff'),
  url('../#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ywvu0a##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete {
  &:before {
    content: $icon-delete;
    color: #1daee9;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
    color: #1daee9;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
    color: #1daee9;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
    color: #21afe9;
  }
}
.icon-gallery {
  &:before {
    content: $icon-gallery;
    color: #21afe9;
  }
}
.icon-membership {
  &:before {
    content: $icon-membership;
    color: #1daee9;
  }
}
.icon-conversations {
  &:before {
    content: $icon-conversations;
    color: #00b1ed;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
    color: #00b1ed;
  }
}
.icon-plane {
  &:before {
    content: $icon-plane;
    color: #1daee9;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
    color: #1daee9;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
    color: #21afe9;
  }
}
.icon-heart-full {
  &:before {
    content: $icon-heart-full;
  }
}
.icon-more {
  &:before {
    content: $icon-more;
    color: #00b1ed;
  }
}
.icon-back {
  &:before {
    content: $icon-back;
  }
}
.icon-filter .path1 {
  &:before {
    content: $icon-filter-path1;
    color: rgb(252, 194, 2);
  }
}
.icon-filter .path2 {
  &:before {
    content: $icon-filter-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-filter-on .path1 {
  &:before {
    content: $icon-filter-on-path1;
    color: rgb(255, 255, 255);
  }
}
.icon-filter-on .path2 {
  &:before {
    content: $icon-filter-on-path2;
    margin-left: -1em;
    color: rgb(252, 194, 2);
  }
}

