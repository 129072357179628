$icomoon-font-family: "it" !default;
$icomoon-font-path: "fonts" !default;

$icon-delete: "\e901";
$icon-logout: "\e902";
$icon-settings: "\e903";
$icon-close: "\e908";
$icon-gallery: "\e905";
$icon-membership: "\e90a";
$icon-conversations: "\e90d";
$icon-menu: "\e906";
$icon-plane: "\e907";
$icon-search: "\e90e";
$icon-heart: "\e90f";
$icon-heart-full: "\e911";
$icon-more: "\e913";
$icon-back: "\e914";
$icon-filter-path1: "\e915";
$icon-filter-path2: "\e916";
$icon-filter-on-path1: "\e917";
$icon-filter-on-path2: "\e918";
