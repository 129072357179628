.StripeElement {
  margin: 100px 0 30px 0;
  max-width: $max-width;
  padding: 10px 14px;
  font-size: 1em;
  font-family: $icomoon-font-family;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: $white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

button.pay-button-stripe {
  width: 100%;
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: $white;
  border-radius: 100px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.025em;
  background-image: linear-gradient(102deg, $blue-light, $blue-hover);
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-bottom: 25px;
}
button.pay-button-stripe:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}



// Unsuccess
.payment-response-unsuccess {
  text-align: center;

  h1 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.29px;
    text-align: center;
    color: #cc3300;
  }
  h2 {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.22px;
    text-align: center;
    color: #84939c;
  }
}

// Success

.payment-response-success {
  text-align: center;
  padding: 50px 0 0 0;

  h1.thank-you {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.29px;
    text-align: center;
    color: #222222;

  }

  h1 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.29px;
    text-align: center;
    color: #1daee9;
  }

  h2 {
    bottom: 0;
    margin-top:70px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.22px;
    text-align: center;
    color: #84939c;
  }
}

