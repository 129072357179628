.user {
  font-size: 16px;

  &--name {
    z-index: 1;
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: 22px;
    color: #FFF;
  }

  &--location {
    position: absolute;
    left: 20px;
    bottom: 6px;
    color: #FFF;
    font-size: 16px;
    z-index: 1;
  }

  &--info {
    width: 100%;
    padding: 0 20px;

    &--item {
      margin-top: 19px;
    }
  }

  &--wants-to-visit-item {
    display: inline-block;
    font-size: 14px;
    border-radius: 20px;
    border: solid 1px #1daee9;
    color: #1daee9;
    padding: 2px 15px;
    margin: 0 4px 6px 0
  }

  &--aboutMe-text {
    color: #84939c;
    font-size: 16px;
  }

  &--fixed-expander {
    width: 100%;
    height: 80px;
  }

  &--fixed-bottom-area {
    position: fixed;
    height: 60px;
    bottom: 0;
    left:0;
    right:0;
    z-index: 2;

    .area-centered {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;

      &--padded {
        padding: 0 10px;
      }
    }

    &--button {
      display: inline-block;
      width: 75%;
      text-align: center;
      height: 48px;
      line-height: 48px;
      border-radius: 100px;

      background-image: linear-gradient(150deg, #9ebc39, #76bd4f 31%, #71bd52 36%, #31bf75 86%, #20c07f);

      color: #FFF;
      font-size: 18px;
    }

    &--favorite {
      border-radius: 100px;
      display: inline-block;
      float: right;
      text-align: center;
      width: 49px;
      line-height: 49px;
      height: 49px;
      padding: 2px 3px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.21);
      background: #ffffff;
      font-size: 28px;
      color: #ea0f0a;

      &:hover {
        color: #ea0f0a;
      }

      &.is-liked{
        .icon-not-liked {
          display: none;
        }
      }
    }

    &--send-button {
      display: inline-block;
      float: right;
      font-size: 28px;
      margin-top: 6px;
      color: #1daee9;
    }
  }
}


