$gallery-image: 5;
$image-loading: 8;
$slide-image: 5;
$gallery-fav: 9;

.gallery-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  justify-content: baseline;
  padding: 0 5px;
}

.gallery-list-one {
  display: block;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  justify-content: baseline;
  padding: 0 5px;
}

.gallery-listItem {
  line-height: 0;
  color: #FFF;
  position: relative;
  overflow: hidden;
  flex: 0 50%;
  padding: 3px;
  /* Change height to resize image in Gallery */
  height: 190px;
}

.gallery-listItem:before {
  content: '';
  padding-top: 100%;
  float: left;
}

.gallery-listItem-inny {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 2px solid #FFF;
}

.gallery-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  z-index: $image-loading;
}

.gallery-image {
  width: 100%;
  position: relative;
  z-index: $gallery-image;
  margin-top: -10%;
}

.gallery-fav {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: $gallery-fav;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 13px;
  padding: 5px;
  margin: -10px;
  color: red;
  background: #FFF;
  border-radius: 25px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.21);
}

.carousel {
  background: #FAFAFA;
  margin-top: 1px;
}

.carousel-cell {
  width: 100%;
  height: 400px;
  background: #333;
}

.flickity-viewport {
  height: 350px !important;
}

.carousel-cell-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;

  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.gallery-video-container {
  position: relative;
  height: 100%;
}

.gallery-name {
  height: 100%;
  video {
    margin-top: -100px;
  }
}

.gallery-video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  & i {
    cursor: pointer;
    font-size: 50px;
  }
}

/* fade in lazy loaded image */
.carousel-cell-image.flickity-lazyloaded,
.carousel-cell-image.flickity-lazyerror {
  opacity: 1;
}

@media only screen and (max-width: 500px) {
  .gallery-listItem {
    height: 170px;
  }
}

@media only screen and (max-width: 361px) {
  .gallery-listItem {
    height: 150px;
  }

  .gallery-name {
    video {
      margin-top: -80px;
    }
  }
}

@media only screen and (max-width: 321px) {
  .gallery-listItem {
    height: 130px;
  }

  .gallery-name {
    video {
      margin-top: -60px;
    }
  }
}