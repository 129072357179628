nav.navigation {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $nav-z-index;
  color: $blue-hover;

  .nav-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $max-width;
    height: 49px;
    margin: 0 auto;
    padding: 0 16px;
    background: $white;

    .item-right {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    .item-left {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-start;
    }

    .item-back-to-gallery-from-conversations {
      position: absolute;
      left: 16px;
      top: 13px;
      opacity: 0.4;
    }

    .item-back-to-gallery-from-settings {
      position: absolute;
      right: 16px;
      top: 13px;
      opacity: 0.4;
    }

    .item-user {
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;

      &--wrapper {
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--image-area {
          height: 36px;
          padding-right: 6px;

          &--image {
            border-radius: 100px;
            height: 36px;
            width: 36px;
          }
        }

        &--name {
          font-weight: 600;
          letter-spacing: -0.28px;
          color: $nav-blue;
        }
      }
    }

    .search-input {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0 20px;
      background: $nav-search-background;
      border: 2px solid $nav-search-background;
      color: $nav-blue;
      border-radius: 100px;
      height: 32px;
      padding: 0 10px;

      &--area {
        overflow: hidden;
        width: 100%;

        input {
          background: transparent;
          border: none;
          padding: 0;
          font-size: 16px;
          width: 100%;
          color: $nav-blue;


          &::placeholder {
            color: $nav-blue;
            opacity: 0.3;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}