.noUi-target {
  border: none !important;
  height: 2px !important;
}

.noUi-connect {
  background: $blue-darker !important;
}

.noUi-handle {
  width: 16px !important;
  height: 16px !important;
  right: -15px !important;
  top: -7px !important;
  border-radius: 8px;
  background-color: $blue-darker !important;
  border: 1px solid $blue-darker !important;
  box-shadow: none !important;

  &:before {
    background: none !important;
  }

  &:after {
    background: none !important;
  }
}

.noUi-tooltip {
  border: 0;
  color: $black !important;
  padding: 5px !important;
}