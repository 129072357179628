// Subscriptions component

span.subscriptions-nav-title {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  text-align: center;
  color: #1daee9;
}
.subscriptions {
  padding: 20px 10px;
  max-width: $max-width;
  background: #f0f6fa;
  min-height: 90vh;

  &--image-area {
    text-align: center;

    img {
      margin-top: 20px;
      max-width: 60%;
    }
  }

  &--info-area {
    background: #fff;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 10px;
    border-radius: 5px;
    line-height: 30px;
    vertical-align: middle;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;

    &--title {
      float: left;
    }

    &--value {
      font-weight: 700;
      float: right;
    }

    &--cancel-button {
      width: 100%;
      white-space: nowrap;
      border: 0;
      outline: 0;
      height: 45px;
      line-height: 45px;
      padding: 0 14px;
      box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
      color: $white;
      border-radius: 100px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.025em;
      background-image: linear-gradient(102deg, $blue-light, $blue-hover);
      text-decoration: none;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      margin: 20px 0;
    }

    &--cancel-button:hover {
      color: #fff;
      cursor: pointer;
      background-color: #7795f8;
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
    }
  }

}