.conversation-private-area {
  .messages-list {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 26px 0;

    .message-row {
      display: table;
      width: 100%;
      clear: both;
      padding: 0 10px;

      .cell {
        display: table-cell;
        vertical-align: bottom;
      }

      .cell-right {
        float: right !important;
      }

      .cell-user {
        width: 36px;

        .user-photo {
          vertical-align: bottom;
          width: 36px;
          height: 36px;
          border-radius: 100px;
        }
      }

      &--ago {
        font-size: 14px;
        color: #aeafaf;
        text-align: left;
        margin-left: 73px;

        &.right {
          text-align: right;
          margin-right: 73px;
        }
      }

      .cell-message {
        float: left;

        .recipient-message {
          background: #f1f7fb;
          color: #666666;
          margin: 0 40px 0 15px;
        }

        .sender-message {
          background: #1daee9;
          color: #FFF;
          margin: 0 15px 0 40px;

          .message-link {
            color: #fff;
          }
        }

        .message-link {
          text-decoration: underline;
        }

        .content-area {
          border-radius: 15px;
          padding: 7px 15px 6px 15px;
          position: relative;

          min-width: 80px;
          min-height: 36px;

          .content {
            word-break: break-word;
            word-wrap: break-word;
            font-size: 16px;
            line-height: 1.2;
          }

          .arrow-left {
            width: 0;
            height: 0;
            border-bottom: 1px solid transparent;
            border-top: 8px solid transparent;
            border-right: 8px solid white;
            position: absolute;
            left: -8px;
            bottom: 10px;
            border-right: 10px solid #f1f7fb;
          }

          .arrow-right {
            width: 0;
            height: 0;
            border-bottom: 1px solid transparent;
            border-top: 8px solid transparent;
            border-left: 8px solid transparent;
            position: absolute;
            right: -8px;
            bottom: 10px;
            border-left: 10px solid #1daee9;
          }
        }
      }
    }
  }
}

.conversations-remove-ignore-dropdown {
  position: absolute;
  top: 48px;
  right: 10px;
  height: 100px;
  width:  $max-width/3;
  background: transparent;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.21);

  &--button {
    cursor: pointer;
    padding: 0;
    background: #f1f7fb;
    border: none;
    height: 50%;
    width: 100%;
    font-weight: 600;
    color: #666666;
    font-size: 14px;

    &:hover {
      background: $grey-e;
    }
  }
}

.message-area {
  background: $white;
  height: 60px;
  padding: 0 10px;
  border-top: 1px solid $grey-e;
  position: relative;

  textarea {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 10px;
    padding-left: 10px;
    color: grey;
    font-size: 16px;
    width: calc(100% - 50px);
    height: 60px;
    border: 0;
    overflow: auto;
    resize: none;
  }

  button {
    position: absolute;
    right: 0;
    background: none;
    border: none;
  }

  textarea::placeholder {
    color: $grey-9;
  }

  textarea:focus {
    outline: none;
  }
}

@media only screen and (max-width: 500px) {
  .message-area {
    button {
      top: 10px;
    }
  }
}