@import "popup-preferences";
$bg-blue-price: #73BDEB;

.settings {
  &--info-block {
    background-image: linear-gradient(124deg, $blue-light, $blue-hover);
    text-align: center;
    padding: 16px 0 10px 0;

    &--img {
      border-radius: 100px;
      border: 4px solid $white;
      width: 114px;
      height: 114px;
    }

    &--name {
      padding: 0 0 8px 0;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.19px;
      color: $white;
    }

    &--edit-button {
      display: inline-block;
      width: 206px;
      height: 36px;
      line-height: 36px;
      border-radius: 17.5px;
      background-color: $white;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.25px;
      text-align: center;
      color: $blue-unread;
    }
  }

  &--menu {
    a {
      display: flex;
      align-items: center;
      justify-content: left;
      width: 100%;
      height: 48px;
      font-size: 18px;

      .icon {
        width: 50px;
        text-align: center;
        font-size: 22px;
      }

      .text {
        color: $preferences-age-text;
      }
    }
  }
}

.pay-button {
  text-align: left;
  width: 100% !important;
  display: inline-block !important;
  padding: 0 12px 0 14px;
  height: 58px !important;
  line-height: 58px;
  border-radius: 100px;
  background-image: linear-gradient(102deg, $blue-light, $blue-hover);
  color: $white;
  font-size: 17px;
  font-weight: 600;

  .price {
    float: right;
    height: 37px;
    line-height: 37px;
    margin-top: 10px;
    padding: 0 10px;
    background-color: $bg-blue-price;
    border-radius: 33px;
    font-size: 13px
  }
  .price-with-discount {
    float: right;
    height: 37px;
    margin-top: 10px;
    padding: 0 10px;
    background-color: $bg-blue-price;
    border-radius: 33px;
    font-size: 13px;
    text-align: center;
    line-height: 17px;
  }
}

.app-info {
  font-size: 12px;
  font-weight: 600;
  color: $grey-9;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 340px) {
  .pay-button {
    font-size: 15px;
    .price {
      font-size: 11px;
    }
    .price-with-discount {
      font-size: 9px;
    }
  }
}

@media only screen and (max-width: 360px) and (min-width: 341px) {
  .pay-button {
    font-size: 15px;
    .price {
      font-size: 11px;
    }
    .price-with-discount {
      font-size: 12px;
    }
  }
}