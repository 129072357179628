.react-fancybox .box {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 11;
}

.react-fancybox .thumbnail img {
  border-radius: 100px;
  border: 4px solid $white;
  width: 114px;
  height: 114px;
}

.react-fancybox .box .image-box {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  position: relative;
  width: $max-width;
}

.react-fancybox .box .image-box .caption {
  text-align: center;
  font-size: 14px;
  margin-top: 7px;
}

.react-fancybox .fade-enter {
  animation: fadein 600ms;
}

.react-fancybox .fade-leave {
  animation: fadeout 600ms;
}

.react-fancybox .close-btn {
  background-color: white;
  width: 25px;
  border-radius: 50%;
  height: 25px;
  position: absolute;
  right: -13px;
  top: -13px;
}