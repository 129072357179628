.edit-profile {
  .save-edit-button {
    display: inline-block;
    width: 100%;
    height: 36px;
    line-height: 36px;
    border-radius: 17.5px;
    background-color: $blue-unread;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    text-align: center;
    color: $white;
    border: none;
    margin-top: 10px;

    &:hover {
      background-color: $blue-hover;
    }
  }
}

.edit-profile > .field-group {
  padding: 7px 9px;

  .wants-to-visit-item {
    display: inline-block;
    font-size: 14px;
    border-radius: 20px;
    border: solid 1px #1daee9;
    color: #1daee9;
    padding: 2px 15px;
    margin: 5px 4px 6px 0
  }


  input {
    width: 100%;
    border: 0px;
    font-size: 15px;
    color: $black;

    &:focus {
      outline: none;
    }
  }

  textarea {
    width: 100%;
    border: 0px;
    font-size: 15px;
    color: $black;
    resize: none;

    &:focus {
      outline: none;
    }
  }

}