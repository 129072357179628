.mfp-bg.main-popup {
  background: $white;
}

.main-popup {
  .mfp-container {
    height: 80% !important;
  }
}

.popup-area-margin {
  width: 100%;
  max-width: $max-width;

  .mfp-close {
    position: absolute;
    color: $grey-6 !important;
    opacity: 1 !important;
    font-size: 40px;
    cursor: pointer;
    top: 0;
    right: 0;
    z-index: 1500;
    background: none;
    border: none;
  }
}

.upgrade-direct-margin, .upgrade-adyen-margin {
  max-width: 611px !important;
}

.popup-block {
  text-align: center;
  min-height: 50px;
  padding: 10px 20px;
  background: $white;
  margin: 0 auto;
  z-index: 1046;
}
.popup-content {
  width: $max-width !important;
  padding: 0px !important;
}