@import "popup-login";

.landing-screen {
  display: block;
  position: relative;
  max-width: 500px;
  min-height: 100vh;
  background-image: url('/app/images/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .slogan {
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.02px;
    color: #ffffff;
    margin-left: 20px;
    line-height: 1.08;
  }

  .login-form {
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: center;

    .padded {
      margin: 0 20px;
    }
  }

  .footer-text {
    text-shadow: 0 2px 14px rgba(0, 0, 0, 0.5);
    width: 100%;
    padding:  0 15px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.22px;
    text-align: center;
    color: #ffffff;

    a {
      color: #1daee9;
    }
  }
}

.sign-in-button {
  position: relative;
  line-height: 56px;
  height: 56px;
  width: 100%;
  margin: 10px auto 10px auto;
  display: block;
  background-color: rgba(96, 96, 96, 0.6);
  border-radius: 11px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.19px;
  font-weight: bold;
  border: 2px solid #ffffff;

  &--text {
    color:#ffffff;
  }

  &:hover {
    background-color: rgba(96, 96, 96, 0.7);
  }
}

.cta-button {
  position: relative;
  height: 56px;
  width: 100%;
  margin: 0 auto;
  display: block;
  background-color: #325eb5;
  border: none;
  border-radius: 11px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.19px;
  font-weight: bold;
  vertical-align: middle;
  line-height: 56px;
}

.cta-button.disabled {
  opacity: 0.5 !important;
  text-align: center !important;
  padding: 0 !important;
}


.cta-button > .text {
  display: block;
}

.cta-button.disabled > .text {
  display: none;
}

.cta-button.disabled > .spinner {
  display: block;
}

.cta-button > .spinner {
  display: none;
}

.cta-button.disabled:hover {
  background-color: #2189e6 !important;
}