#login-form {
  padding: 30px 0;

  .login-error {
    width: 100%;
    display: block;
    color: indianred;
    font-size: 18px;
    font-weight: 600;
    text-align: center;

  }

  input {
    font-size: 16px;
    width: 100%;
    max-width: 300px;
    margin: 5px 0;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
  }

  .login-button {
    width: 100%;
    max-width: 300px !important;
    background-color: $blue-darker;
    height: 45px;
    margin: 30px auto 0 auto;
    display: block;
    border: none;
    border-radius: 11px;
    color: $white;
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.19px;
    font-weight: bold;
  }
}