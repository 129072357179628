.conversations {
  &--title {
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    letter-spacing: -0.25px;
    color: #84939c;
    display: inline-block;
    margin-left: 10px;
  }

  &--unread-title {
    display: inline-block;
    color: #e24b69;
    font-weight: 500;
    margin-left: 5px;

    .unread-counter {
      display: inline-block;
      min-width: 20px;
      height: 20px;
      line-height: 19px;
      text-align: center;

      font-size: 14px;
      background: #e24b69;
      color: #FFF;
      border-radius: 100px;
    }
  }

  &--list {
    display: block;
    //background: grey;

    &--item {
      position: relative;
      width: 100%;
      height:82px;
      margin-bottom: 0;
      border-bottom: 1px solid #CCCCCC;

      &--photo {
        position: absolute;
        left: 10px;
        top: 4px;

        &--img {
          width: 72px;
          height: 72px;
          border-radius: 100px;
          padding: 3px;
          border: 2px solid #FFF;

          &.border-unread{
            border: 2px solid #1daee9;
            margin: 0px 2px;
          }
        }
      }

      .time {
        font-size: 13px;
        letter-spacing: -0.28px;
        color: #b1b1b1;
        position: absolute;
        top: 11px;
        right: 10px;
      }

      &--delete-area {
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        height:81px;
        line-height: 81px;
        background: red;
        color: #FFF;
      }

      &--name {
        position: absolute;
        left: 90px;
        top: 18px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.32px;
        color: #4a4a4a;
      }

      &--content {
        position: absolute;
        left: 90px;
        top: 40px;
        right: 20px;
        text-overflow: ellipsis;

        overflow: hidden;
        white-space: nowrap;
        letter-spacing: -0.28px;
        color: #5b6c76;

        &.content-unread {
          color: #1daee9;
          font-weight: bold;
        }

      }
    }
  }
}

.photo-blurred {
  -webkit-filter: blur(2px);
  -webkit-clip-path: circle(50% at center);
}