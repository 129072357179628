.ContactForm {
  padding: 25px;
}
.ContactForm label,
.ContactForm input,
.ContactForm textarea {
  display: block;
  width: 100%;
  -webkit-appearance: none;
}
.ContactForm input,
.ContactForm textarea {
  padding: 0 14px;
  border-radius: 0;
  border: 1px solid #c4d2db;
  font-size: 15px;
  color: #555555;
}
.ContactForm input.is-invalid,
.ContactForm textarea.is-invalid {
  border-color: #eb5658;
}
.ContactForm textarea {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ContactForm label {
  margin-bottom: 8px;
  font-size: 13px;
  text-transform: uppercase;
  color: #84939c;
}
.ContactForm input {
  height: 50px;
}
.ContactForm input[type="submit"] {
  margin-top: 24px;
  border-radius: 4px;;
  text-transform: uppercase;
  border: none;
  font-size: 16px;
  background: #68b9d5;
  color: white;
}
.ContactForm-group + .ContactForm-group {
  margin-top: 20px;
}
.ContactForm input::-webkit-input-placeholder {
  color: #999999;
}
.ContactForm-error {
  margin-top: 6px;
  color: #eb5658;
  font-size: 12px;
}
input[type=submit][disabled=disabled] {
  opacity: 0.7;
}
