.flickity-page-dots {
  position: fixed;
  z-index: 10;
  max-width: 400px;
  margin-left: 50px;
  top: 15px;
  bottom: auto !important;
}
/* white circles */
.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 1px solid $blue;
}

/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: $blue;
}

.carousel-cell-image {
  max-height: none !important;
  min-width: 100%;
  min-height: 100%;
  margin-top: -10% !important;
}

.flickity-button:disabled {
  pointer-events: unset !important;
}

@media only screen and (max-width: 500px) {
  .flickity-page-dots {
    max-width: 72.5%;
  }
  // Adds better scrolling behaviour for gallery user images
  .carousel {
    touch-action: none;
  }
  .carousel-cell-image {
    scroll-behavior: auto;
  }
}
// Add better scrolling behaviour for gallery user images when orientation is landscape
@media screen and (orientation:landscape) {
  .carousel {
    touch-action: none;
  }
  .carousel-cell-image {
    scroll-behavior: auto;
  }
}