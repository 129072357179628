.Terms {
    padding: 36px 20px 48px;
    line-height: 1.6;
    font-size: 14px;
}
.Terms ol {
    list-style-type: none;
    counter-reset: item;
}
.Terms li {
    list-style-type: none;
}
.Terms ol ol {
    padding: 0 0 0 0;
}
.Terms ol h2:before {
    content: counters(item, ".")". ";
    counter-increment: item;
}
.Terms ol ol li:before {
    content: counters(item, ".")".";
    counter-increment: item;
    display: inline-block;
    margin-right: 4px;
    font-weight: 500;
}
.Terms li > p:first-child {
    display: inline;
}
.Terms h1 {
    margin-bottom: 16px;
    font-size: 16px;
    color: black;
}
.Terms h2 {
    margin-top: 24px;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: 16px;
    color: black;
}
.Terms li + li {
    margin-top: 12px;
}
.Terms p + ol {
    margin-top: 12px;
}