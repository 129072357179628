.location {
  &--autocomplete {
    display: flex;
    flex-direction: column;
    &-input {

    }
    &-item {
      width: 100%;
      background: white;
      border-bottom: 1px solid #f5f5f5;
      padding: 10px 15px;
      font-size: 16px;
      color: #000000;
      cursor: pointer;
      transition: 0.3s all ease-in;
      text-overflow: ellipsis;
      max-height: 39px;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      &:last-of-type {
        border-bottom: 0;
      }
      &:hover {
        background: #f3f3f3;
      }
    }
  }
}

#location--autocomplete-results {
  box-shadow: 0 2px 8px rgba(0,0,0,0.6);
}
