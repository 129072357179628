.round {
  position: relative;

  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 32px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32px;

    &:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 8px;
      left: 8px;
      opacity: 0;
      position: absolute;
      top: 9px;
      transform: rotate(-45deg);
      width: 14px;
    }

    .text {
      margin-left: 36px;
      line-height: 31px;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
  }

  input[type="checkbox"]:checked + label {
    background-color: #00b1ed;
    border-color: #00b1ed;
  }

  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}