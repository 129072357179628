@import "details/checkbox-rounded";

.popup-preferences {
  text-align: center;
  margin-top: 50px;

  h1 {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.41px;

    color: $blue-unread;
  }

  .i-am-searching-for {
    margin: 31px 0 50px 0;
    font-weight: 600;
    line-height: 1.25;

    color: $black;
  }

  .area-select-gender {
    display: flex;
    justify-content: space-around;

    .checkbox-container {
      width: 100px;
    }
  }

  .age-text {
    margin-top: 50px;
    font-weight: bold;
    letter-spacing: -0.19px;
    color: $preferences-age-text;
  }

  .age-slider {
    width: 100%;
    max-width: 300px;
  }

  .button-save {
    width: 100%;
    font-size: 18px !important;
    margin-bottom: 40px;
    border: none;
  }

  .button-blue {
    margin-top: 35px;
    display: inline-block;
    padding: 0 20px;
    background-image: linear-gradient(102deg, #16c4ff, #1eaae5);
    font-weight: 600;
    color: $white;
    height: 58px;
    line-height: 58px;
    border-radius: 100px;

    &:hover {
      color: #FFF;
    }
  }
}

.membership-popup-title {
  h1 {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.41px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    color: $blue-unread;
    position: relative;
    margin-top: 5px;
    padding-bottom: 34px;
  }
}
